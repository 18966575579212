import React, { createContext, useCallback, useContext, useMemo } from "react";
import * as R from "ramda";
import { useLocation } from "react-router-dom";
import { useAgentSettings } from "utils/useAgentSettings";
import { permissionsSatisfy, permissionsSatisfyAny, toolsPermissions } from "./permissions";
import { useIntegrator } from "context/integratorContext";
import { useToken } from "context/tokenContext";
import { ctxWithIntegrator } from "utils/auth";
import {
  TILIA,
  debugFn,
  debugOff,
  exists,
  formatDateTime,
  getLocQueryString,
  getTokenFromJWT,
  isBeforeNow,
  isProd,
} from "utils";
import { logout } from "api/toolsWeb";
import { useApolloClient } from "@apollo/client";

const AgentContext = createContext();

const AgentProvider = (props) => {
  const apolloClient = useApolloClient();
  const location = useLocation();
  const { sessionJWT, setToken, deleteToken } = useToken();

  const [agentSettings, setAgentSetting] = useAgentSettings(getTokenFromJWT(sessionJWT).account_id);

  const token = useMemo(() => getTokenFromJWT(sessionJWT), [sessionJWT]);

  const integrator = useIntegrator(token.integrator);
  const params = new URLSearchParams(location.search);
  const includesDebug = params.has("__debug");
  const debug = useMemo(() => {
    if (!isProd() && (agentSettings?.debugMode || includesDebug)) {
      return debugFn;
    }
    return debugOff;
  }, [agentSettings?.debugMode, includesDebug]);

  const debugToken = (location, token, agentSettings) => {
    const qs = getLocQueryString(location);
    if (R.includes("token", R.keys(qs))) {
      debugFn("token:", {
        ...token,
        expiration: formatDateTime(token.exp * 1000),
        expired: isTokenExpired(token),
        agentSettings,
      });
    }
  };
  debugToken(location, token, agentSettings);
  const isTokenExpired = (token) => {
    return token && isBeforeNow(token.exp * 1000);
  };
  const tokenExpired = useCallback((token) => isTokenExpired(token), []);

  const logoutAgent = () => {
    logout(agent);
    apolloClient.resetStore();
    deleteToken();
  };
  const deleteAgent = logoutAgent;

  const setAgent = useCallback(
    (jwt) => {
      apolloClient.resetStore();
      setToken(jwt);
    },
    [setToken, apolloClient],
  );

  const agent = useMemo(() => {
    const processResponseError = (responseError) => {
      debug(
        "[agentContext::processResponseError]",
        responseError.name,
        responseError.statusCode,
        responseError.loggingMessage,
      );
      if (responseError.statusCode === 401) {
        logout(agent);
        apolloClient.resetStore();
        deleteToken();
      }
    };

    const processResponse = (response) => {
      const newToken = response.headers.get("x-tilia-token-refreshed");
      if (exists(newToken)) {
        debug("[agentContext::processResponse] updating token");
        setAgent(newToken);
      }
    };

    return sessionJWT
      ? {
          jwt: sessionJWT,
          ...token,
          debug,
          tokenExpiration: formatDateTime(token.exp * 1000),
          tokenExpired: () => tokenExpired(token),
          hasToolsPermissions: () => permissionsSatisfyAny(toolsPermissions, token.permissions),
          hasPermissions: (requiredPermissions, resource = "") => {
            const result =
              integrator.accessResource(resource) &&
              permissionsSatisfy(requiredPermissions, token.permissions);
            if (!result) debug("hasPermissions", result, requiredPermissions);
            return result;
          },
          hasPermissionsAny: (requiredPermissions, resource = "") => {
            const result =
              integrator.accessResource(resource) &&
              permissionsSatisfyAny(requiredPermissions, token.permissions);
            if (!result) debug("hasPermissionsAny", result, requiredPermissions);
            return result;
          },
          isOwnAccount: R.equals(token.account_id),
          // "integrator" is spread in the ...token TODO: fix usages, then change this
          integratorConfig: integrator,
          isTilia: () => integrator?.integratorId === TILIA,
          processResponseError,
          processResponse,
          withIntegratorContext: (ctx) => R.assoc("integratorContext", ctx, token),
        }
      : null;
  }, [apolloClient, deleteToken, sessionJWT, token, tokenExpired, debug, integrator, setAgent]);

  const withIntegratorContext = (integrator) => ctxWithIntegrator(integrator, agent);

  return (
    <AgentContext.Provider
      value={{
        agent,
        setAgent,
        deleteAgent,
        logoutAgent,
        agentSettings,
        setAgentSetting,
        debug,
        withIntegratorContext,
      }}
      {...props}
    />
  );
};

const useAgent = () => useContext(AgentContext);

export { useAgent, AgentProvider };
