import { Box, Flex, Heading, HStack } from "@chakra-ui/react";
import { AccountBlockTags } from "components/Account";
import { CopyableText } from "components/Copyable";
import IntegratorLogo from "components/IntegratorLogo/IntegratorLogo";
import { exists } from "utils";
import { AccountTag } from "components/Account";
import { useTitle } from "context/titleContext";

export default function AccountHeader({ data }) {
  const emailOrUsername = data?.account?.email || data?.account?.username;
  useTitle({ page: emailOrUsername }, { clear: true });

  return (
    <Flex as="header" gap={3} border="px solid red">
      <Box>
        <IntegratorLogo integratorName={data?.account?.integrator} />
      </Box>
      <Box>
        <Flex alignItems="center" gap={3}>
          <Heading as="h1" m={0} size="lg">
            {emailOrUsername}
          </Heading>
          <AccountBlockTags accountId={data?.account.accountId} />
        </Flex>
        <CopyableText text={data?.account.accountId} buttonSize="xs">
          {data?.account.accountId}
        </CopyableText>
        {exists(data?.account?.tags) && (
          <HStack mt={2} spacing={2}>
            {data.account.tags.map((tag) => (
              <AccountTag key={tag.tagId} tag={tag} />
            ))}
          </HStack>
        )}
      </Box>
    </Flex>
  );
}
