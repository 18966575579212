import {
  CardBodyRow,
  FormattedAddress,
  PMCard,
  PMCardBody,
  PROVIDER_MAP,
  StandardPMCardFooter,
  StandardPMCardHeader,
  formatExpirationDate,
  getMaskedCardNumber,
  usePaymentsMenuItem,
} from "./common";
import { useDisclosure } from "@chakra-ui/react";
import { exists } from "@tilia-tools/utils";
import BinData from "./BinData";
import { useDeactivationModal } from "./DeactivationModal";

export const CreditCardPaymentMethodCard = ({ paymentMethod, onUpdate, onSelectPayments }) => {
  const [DeactivationModal, deactivationMenuItem] = useDeactivationModal(paymentMethod);
  const paymentsMenuItem = usePaymentsMenuItem(paymentMethod, onSelectPayments);
  const { isOpen, onOpen: onOpenBinData, onClose: onCloseBinData } = useDisclosure();

  const actionItems = [
    deactivationMenuItem,

    ...(paymentMethod.bin
      ? [
          {
            label: "View BIN Data...",
            onClick: onOpenBinData,
          },
        ]
      : []),
    paymentsMenuItem,
  ];

  return (
    <PMCard paymentMethod={paymentMethod}>
      <StandardPMCardHeader paymentMethod={paymentMethod} actionItems={actionItems} />
      <PMCardBody paymentMethod={paymentMethod}>
        <CardBodyRow label="Name" value={paymentMethod.fullName} />
        <CardBodyRow
          label="Card Number"
          value={getMaskedCardNumber(paymentMethod.bin, paymentMethod.lastFour)}
        />
        <CardBodyRow label="Expiration" value={formatExpirationDate(paymentMethod.expiration)} />
        <CardBodyRow
          label="Provider"
          value={PROVIDER_MAP[paymentMethod.provider] || paymentMethod.provider || "-"}
        />
        <CardBodyRow label="Address" value={<FormattedAddress data={paymentMethod.address} />} />
      </PMCardBody>
      <StandardPMCardFooter paymentMethod={paymentMethod} />

      {exists(deactivationMenuItem) && <DeactivationModal onSuccess={onUpdate} />}
      {paymentMethod.bin && (
        <BinData method={paymentMethod} isOpen={isOpen} onClose={onCloseBinData} />
      )}
    </PMCard>
  );
};
