import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  gql,
  HttpLink,
  InMemoryCache,
  useLazyQuery,
  useMutation as useMutationApollo,
} from "@apollo/client";
import { useAgent } from "context/agentContext";
import { useToken } from "context/tokenContext";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { exists, isFunction } from "utils";
import { ROUTE_BASENAME } from "utils/environment";

// useQuery (Apollo style)
//
// Wrapper around the apollo-client useLazyQuery and returning optional permissions check results.
//
// query: Either a query document (resulting from apollo-client's gql
// function) or a function that will be called with the params from the
// current route and the current agent
//
// options: the options to pass to useQuery,
// returns: the useLazyQuery result, augmented with permission check result
//
// Example:
//
// import { useQuery, getAccountTransactions } from "context/graphqlContext";
// import { READ_INVOICES } from "context/permissions";
// ...
// const { data, error, loading, permissionsFailed } = useQuery(getAccountTransactions), {
//   ...options for useQuery
//   permissions: READ_INVOICES,
// });
//
export const useQuery = (query, options = {}) => {
  const params = { ...useParams(), ...options.variables };
  options.variables = params;
  // TODO handle no agent?
  const { agent } = useAgent();

  const { permissions, resource } = options;
  const [permissionState, setPermissionState] = React.useState(null);
  React.useEffect(() => {
    setPermissionState(agent.hasPermissions(permissions, resource));
  }, [permissions, agent, resource]);
  const finalQuery = isFunction(query) ? query(params, agent) : query;

  // use the lazy version of the query to defer execution until after the
  // permissions check
  const [lazyQuery, apolloState] = useLazyQuery(finalQuery, options);

  React.useEffect(() => {
    if (permissionState) {
      lazyQuery();
    } else if (permissionState === false) {
      return () => ({ permissionsFailed: true });
    } else {
      // handle state before setPermissionState is called
      return () => ({ loading: true });
    }
  }, [lazyQuery, permissionState]);

  return { ...apolloState, permissionsFailed: permissionState === false };
};

export const useMutation = (query, options = {}) => {
  // TODO handle no agent?
  const { agent } = useAgent();

  const { permissions, resource } = options;
  const [permissionState, setPermissionState] = React.useState(null);

  React.useEffect(() => {
    setPermissionState(agent.hasPermissions(permissions, resource));
  }, [permissions, agent, resource]);

  let finalQuery = query;

  const [mutateFunction, apolloState] = useMutationApollo(finalQuery, options);

  return { ...apolloState, mutateFunction, permissionsFailed: permissionState === false };
};

/**
 * Returns the currency amount as a BigInt value or null. Used to mirror the scalar types
 * in the schema definition on the GraphQL server side.
 *
 * @param {number|bigint|null} amountOrNull
 * @returns {bigint|null}
 */
function readCurrencyAmount(amountOrNull) {
  return amountOrNull === null ? null : BigInt(amountOrNull);
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        settlement: {
          read(_, { args, toReference }) {
            return toReference({
              __typename: "Settlement",
              settlementId: args.settlementId,
              integrator: args.integrator,
            });
          },
        },
      },
    },
    Settlement: {
      keyFields: ["settlementId", "integrator"],
    },
    Account: {
      keyFields: ["accountId"],
    },
    Payout: {
      keyFields: ["id", "invoiceType"],
      fields: { invoiceType: { read: (value) => value || "INVOICE_TYPE" } },
    },
    TokenPurchase: {
      fields: {
        amount: { read: readCurrencyAmount },
        tokenAmount: { read: readCurrencyAmount },
        integratorFeeAmount: { read: readCurrencyAmount },
        tiliaFeeAmount: { read: readCurrencyAmount },
      },
    },
    TokenConversion: {
      fields: {
        amount: { read: readCurrencyAmount },
        convertedAmount: { read: readCurrencyAmount },
        integratorFeeAmount: { read: readCurrencyAmount },
        tiliaFeeAmount: { read: readCurrencyAmount },
      },
    },
  },
});

const httpLink = new HttpLink({ uri: `${ROUTE_BASENAME}/graphql` });

const authMiddleware = (sessionJWT, setToken) =>
  new ApolloLink((operation, forward) => {
    // add the authorization to the headers and give the operation name to the network tab
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: sessionJWT ? `Bearer ${sessionJWT}` : "",
      },
      uri: `${ROUTE_BASENAME}/graphql?_o=${operation.operationName}`,
    }));

    return forward(operation).map((response) => {
      const maybeRefreshedJWT = operation
        .getContext()
        .response.headers.get("x-tilia-token-refreshed");
      if (exists(maybeRefreshedJWT)) {
        setToken(maybeRefreshedJWT);
      }

      return response;
    });
  });

export const GraphQLProvider = (props) => {
  const { sessionJWT, setToken } = useToken();

  const client = useMemo(
    () =>
      new ApolloClient({
        cache,
        link: concat(authMiddleware(sessionJWT, setToken), httpLink),
        connectToDevTools: true,
      }),
    [sessionJWT, setToken],
  );
  return <ApolloProvider {...props} client={client} />;
};

// ===============================================================
// as more of these emerge, move to a file / directory
// ===============================================================
export const getAccountTransactions = gql`
  query GetAccountTransactions($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      transactionHistory {
        invoice_id
        state
        created
        symbol_total
        line_items {
          amount
          line_item_id
          note
          reference_id
          sku
          symbol_amount
        }
        payment_methods {
          amount
          payment_method_id
          description
          symbol_amount
        }
      }
    }
  }
`;

export const getIntegrators = gql`
  query getIntegrators {
    integrators {
      id
      displayName
    }
  }
`;

export const getSearchResults = gql`
  query GetSearchResults(
    $searchCriteria: String!
    $currentPage: Int!
    $pageSize: Int!
    $sortField: String!
    $sortDir: String!
  ) {
    ToolsSearch(
      searchCriteria: $searchCriteria
      currentPage: $currentPage
      pageSize: $pageSize
      sortField: $sortField
      sortDir: $sortDir
    ) {
      accounts {
        username
        account_id
        integrator
        created
        matched_on
      }
      meta {
        currentPage
        pageSize
        sortField
        sortDir
        total
      }
    }
  }
`;

export const getAccountGDPR = gql`
  query GetAccountGDPR($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      integrator
      gdpr {
        documents {
          pii_id
          file_name
          file_mime_type
          ts
        }
      }
    }
  }
`;

export const getAccountPaymentMethods = gql`
  query GetAccountPaymentMethods($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      integrator
      paymentMethods {
        paymentMethodId
        methodClass
        displayString
        created
        updated
        firstName
        lastName
        fullName
        pmState
        processingCurrency
        provider
        providerEmail
        providerVerified
        providerWalletId
        pspHashCode
        pspReference
        bin
        lastFour
        expiration
        tags {
          tagId
          tagName
          namespace
          created
          updated
          status
        }
        address {
          street
          street2
          city
          region
          country
          postalCode
        }
      }
    }
  }
`;

export const getAccountDetails = gql`
  query GetAccountDetails($accountId: ID!) {
    account(accountId: $accountId) {
      accountId
      integrator
      username
      email
      emails {
        emailId
        email
        emailTypes
        isVerified
        bounceReason
        bounceSource
        bounceTime
      }
      personas {
        id
        name
        handle
        is_default
        integrator
      }
      notes {
        id
        subject
        body
        created
        updated
        author {
          username
          accountId
        }
      }
      tos {
        signed
      }
      kyc {
        kycMatchChecks {
          matchScore
          matchedAccount {
            accountId
            username
          }
        }
      }
      tags {
        tagId
        tagName
        namespace
        created
        updated
        status
      }
    }
  }
`;
