import { Flex, Text } from "@chakra-ui/react";
import { getBin } from "api/jpm";
import DetailList from "components/DetailList/DetailList";
import { ModalErrors } from "components/Modal";
import Modal from "components/Modal/Modal";
import InfoTooltip from "components/Tooltip/InfoTooltip";
import { useAgent } from "context/agentContext";
import { useQuery } from "react-query";
import { Spinner } from "components/Spinner";
import { useState } from "react";
import { StandardPMHeader } from "./common";

export default function BinData({ isOpen, onClose, method }) {
  const { bin } = method;
  const { agent } = useAgent();
  const [error, setError] = useState();

  const { isLoading, data } = useQuery(["getBinData", bin], () => getBin(bin, agent), {
    enabled: isOpen,
    onSuccess: (data) => {
      if (data.status !== 200) {
        setError(data.payload.error);
      }
    },
  });

  return (
    <Modal
      title={
        <>
          <Flex alignItems="center" mb={3}>
            BIN Data
            <InfoTooltip
              label={
                <>
                  <Text>
                    BIN (Bank Identification Number) data refers to the first six digits of a credit
                    card number. These digits represent the issuer identification number and are
                    used to identify the issuing bank or financial institution of a credit card.
                  </Text>
                  <Text>
                    The BIN data provides information about the card's issuer, including the bank or
                    financial institution's name, location, and other details. It helps in verifying
                    the authenticity of the card and determining the card's type, such as whether it
                    is a Visa, Mastercard, American Express, or another brand.
                  </Text>
                </>
              }
            />
          </Flex>
          <StandardPMHeader paymentMethod={method} />
        </>
      }
      onClose={onClose}
      isOpen={isOpen}
    >
      {error && <ModalErrors errors={[error]} />}
      {isLoading && <Spinner />}
      {data && !error && Object.keys(data.payload).length > 0 && (
        <DetailList
          details={Object.keys(data.payload).map((key) => [
            BIN_FIELDS[key] || key,
            data.payload[key],
          ])}
        />
      )}
    </Modal>
  );
}

const BIN_FIELDS = {
  bin_low: "BIN Low",
  bin_high: "BIN High",
  max_account_number_length: "Max Account Number Length",
  country_code: "Country Code",
  card_product: "Card Product",
  card_indicator: "Card Indicator",
  default_card_type: "Default Card Type",
  bin_update_date: "BIN Update Date",
  network_indicator: "Network Indicator",
  ebt_state: "EBT State",
  consumer_digital_payment_token_indicator: "Digital Payment Token Indicator",
  prepaid_indicator: "Prepaid Indicator",
  reloadable_prepaid_indicator: "Reloadable Prepaid Indicator",
  durbin_regulated_rate_indicator: "Durbin Regulated Indicator",
  issuer_name: "Issuer Name",
  p2p_money_transfer_indicator: "P2P Money Transfer Indicator",
  b2b_or_b2c_money_transfer_indicator: "B2B/B2C Money Transfer Indicator",
  p2p_fast_fund_indicator: "P2P Fast Fund Indicator",
  b2b_or_b2c_fast_fund_indicator: "B2B/B2C Fast Fund Indicator",
};
