import { isNilOrEmpty } from "@tilia-tools/utils";

import { CreditCardPaymentMethodCard } from "./CreditCardPaymentMethodCard";
import { PaypalPaymentMethodCard } from "./PaypalPaymentMethodCard";
import { SkrillPaymentMethodCard } from "./SkrillPaymentMethodCard";
import { GenericPaymentMethodCard } from "./GenericPaymentMethodCard";

export const PaymentMethodCard = (props) => {
  const { paymentMethod } = props;
  if (isNilOrEmpty(paymentMethod)) {
    return null;
  }

  // not using a switch statement until I'm sure we can key solely off of provider
  if (paymentMethod.provider === "paypal") {
    return <PaypalPaymentMethodCard {...props} />;
  }
  if (
    paymentMethod.provider === "rebilly" ||
    paymentMethod.provider === "adyen" ||
    paymentMethod.provider === "ingenico" ||
    paymentMethod.provider === "worldpay"
  ) {
    return <CreditCardPaymentMethodCard {...props} />;
  }
  if (paymentMethod.provider === "skrill") {
    return <SkrillPaymentMethodCard {...props} />;
  }

  return <GenericPaymentMethodCard {...props} />;
};
