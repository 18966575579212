import React from "react";
import "styled-components/macro";
import { DeleteIcon } from "components/Icons";
import { ErrorButton } from "components/Button";
import { noop } from "utils";
import { useBasicActionModal } from "components/Modal";
import { useAccount } from "context/accountContext";
import useAgentHasPermissions from "context/useAgentHasPermissions";
import { WRITE_PAYMENT_METHODS } from "context/permissions";

const testIdPrefix = "deactivate-payment-method-";

export const useDeactivationModal = (paymentMethod) => {
  const account = useAccount();
  const [BAModal, openModal] = useBasicActionModal();

  const isActive = paymentMethod.pmState === "ACTIVE";
  const deactivatable = useAgentHasPermissions([WRITE_PAYMENT_METHODS]) && isActive;
  const menuItem = deactivatable ? { label: "Deactivate...", onClick: openModal } : null;

  const DeactivationModal = ({ onSuccess = noop }) => {
    const deactivate = async () =>
      await account.deactivatePaymentMethod(paymentMethod.paymentMethodId);

    return (
      <BAModal
        actionIcon={<DeleteIcon />}
        actionText="Deactivate"
        ActionButtonType={ErrorButton}
        submitAction={deactivate}
        onSubmitActionSuccess={onSuccess}
        testIdPrefix={`${testIdPrefix}modal`}
        titleText="Deactivate Payment Method"
      >
        <p>
          Are you sure you want to deactivate {paymentMethod.displayString}? You can't undo this
          action.
        </p>
      </BAModal>
    );
  };

  return [DeactivationModal, menuItem];
};
