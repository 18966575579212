import {
  CardBodyRow,
  FormattedAddress,
  PMCard,
  PMCardBody,
  PROVIDER_MAP,
  StandardPMCardHeader,
  StandardPMCardFooter,
} from "./common";

export const SkrillPaymentMethodCard = ({ paymentMethod }) => {
  const actionItems = [{ label: "Debug", onClick: () => console.log("debug", paymentMethod) }]; // eslint-disable-line no-console

  return (
    <PMCard paymentMethod={paymentMethod}>
      <StandardPMCardHeader paymentMethod={paymentMethod} actionItems={actionItems} />
      <PMCardBody paymentMethod={paymentMethod}>
        <CardBodyRow label="Name" value={paymentMethod.fullName} />
        <CardBodyRow
          label="Provider"
          value={PROVIDER_MAP[paymentMethod.provider] || paymentMethod.provider || "-"}
        />
        <CardBodyRow label="Address" value={<FormattedAddress data={paymentMethod.address} />} />
      </PMCardBody>
      <StandardPMCardFooter paymentMethod={paymentMethod} />
    </PMCard>
  );
};
