import {
  CardBodyRow,
  FormattedAddress,
  PMCard,
  PMCardBody,
  StandardPMCardFooter,
  StandardPMCardHeader,
  usePaymentsMenuItem,
} from "./common";
import VerificationBadge from "components/VerificationBadge/VerificationBadge";
import { exists } from "@tilia-tools/utils";
import { useDeactivationModal } from "./DeactivationModal";

// no card number, no provider, remove duplicate email in body
export const PaypalPaymentMethodCard = ({ paymentMethod, onUpdate, onSelectPayments }) => {
  const [DeactivationModal, deactivationMenuItem] = useDeactivationModal(paymentMethod);
  const paymentsMenuItem = usePaymentsMenuItem(paymentMethod, onSelectPayments);

  const actionItems = [deactivationMenuItem, paymentsMenuItem];

  return (
    <PMCard paymentMethod={paymentMethod}>
      <StandardPMCardHeader paymentMethod={paymentMethod} actionItems={actionItems} />
      <PMCardBody paymentMethod={paymentMethod}>
        <CardBodyRow label="Name" value={paymentMethod.fullName} />
        <CardBodyRow label="Provider Email" value={paymentMethod.providerEmail} />
        <CardBodyRow
          label="Verified"
          value={exists(paymentMethod.providerVerified) ? <VerificationBadge /> : "-"}
        />
        <CardBodyRow label="Address" value={<FormattedAddress data={paymentMethod.address} />} />
      </PMCardBody>

      <StandardPMCardFooter paymentMethod={paymentMethod} />
      {exists(deactivationMenuItem) && <DeactivationModal onSuccess={onUpdate} />}
    </PMCard>
  );
};
