import * as R from "ramda";
import {
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  VStack,
  Wrap,
  useColorModeValue,
} from "@chakra-ui/react";
import { Adyen, Ingenico, Paypal, Rebilly, Worldpay } from "components/Logos";
import { ComplexTooltip } from "components/Tooltip";
import { DotsVerticalIcon } from "components/Icons";
import PaymentMethodLogo from "components/PaymentMethod/PaymentMethodLogo";
import { CopyableText } from "components/Copyable";
import { DetailText } from "components/Text";
import { exists, isNilOrEmpty } from "@tilia-tools/utils";
import { formatDateTime } from "utils";

export { FormattedAddress } from "components/Address";

const STATUS_COLOR_MAP = {
  critical: "red",
  info: "gray",
  warning: "yellow",
};
export const getColorScheme = (status) =>
  R.defaultTo(STATUS_COLOR_MAP["info"], STATUS_COLOR_MAP[status]);

export const getMaskedCardNumber = (bin, lastFour) => {
  if (!bin && !lastFour) {
    return "-";
  }
  return `${bin || ""}******${lastFour || ""}`;
};

export const formatExpirationDate = (dateString) => {
  const expirationRegex = /\d{4}/;
  if (expirationRegex.test(dateString)) {
    return `${dateString.substring(0, 2)}/${dateString.substring(2)}`;
  }
  return dateString || "-";
};

export const usePaymentsMenuItem = (paymentMethod, onSelectPayments) => {
  return {
    label: "Payments...",
    onClick: () => onSelectPayments(paymentMethod.paymentMethodId),
  };
};

///////////////////////////////////////////////////////////////////////////////////////////
//
// atoms
//
//
export const PROVIDER_MAP = {
  adyen: <Adyen />,
  paypal: <Paypal />,
  rebilly: <Rebilly />,
  worldpay: <Worldpay />,
  ingenico: <Ingenico />,
};

export const CardFooterRow = ({ label, value = "-" }) => {
  return (
    <Flex justifyContent="space-between" alignItems="start" w="100%" marginBottom={1}>
      <Box>{label}</Box>
      <Box>{value}</Box>
    </Flex>
  );
};

export const CardBodyRow = ({ label, value = "-" }) => {
  return (
    <Flex justifyContent="space-between" alignItems="start" w="100%" marginBottom={1}>
      <Box>{label}</Box>
      <Box>{value}</Box>
    </Flex>
  );
};

export const PaymentMethodTags = ({ tags }) => {
  if (isNilOrEmpty(tags)) return null;
  return (
    <Wrap>
      {tags.map(({ tagName, status, created, tagId, namespace }) => {
        const body = (
          <Box>
            <Flex justifyContent="space-between">
              <Box mr={4}>Tag</Box>
              <Box>{tagName}</Box>
            </Flex>
            <Flex justifyContent="space-between">
              <Box mr={4}>Namespace</Box>
              <Box>{namespace}</Box>
            </Flex>
            <Flex justifyContent="space-between">
              <Box mr={4}>Status</Box>
              <Box>{status}</Box>
            </Flex>
            <Flex justifyContent="space-between">
              <Box mr={4}>Created</Box>
              <Box>{created}</Box>
            </Flex>

            <Flex justifyContent="space-between">
              <Box mr={4}>Tag Id</Box>
              <Box>{tagId}</Box>
            </Flex>
          </Box>
        );
        return (
          <ComplexTooltip key={tagId} content={body} id={`tt-${tagId}`}>
            <Tag colorScheme={getColorScheme(status)}>{tagName}</Tag>
          </ComplexTooltip>
        );
      })}
    </Wrap>
  );
};

export const PaymentMethodActionsMenu = ({ actionItems }) => {
  if (isNilOrEmpty(actionItems)) return null;
  return (
    <Menu>
      <MenuButton
        data-testid="card-menu-button"
        variant="ghost"
        cursor="pointer"
        // TODO: remove transparent if possible in Chakra 2
        bg={"transparent"}
        aria-label="Actions menu"
        as={IconButton}
        icon={<DotsVerticalIcon />}
      />
      <MenuList>
        {actionItems.filter(exists).map(({ label, ...props }) => (
          <MenuItem key={label} {...props}>
            {label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const PMCard = ({ paymentMethod, children }) => {
  const inactiveColor = useColorModeValue("gray.500", "gray.500");
  const inactiveBgColor = useColorModeValue("gray.100", "gray.700");
  const inactiveFilter = useColorModeValue("grayscale(80%)", "grayscale(80%)");
  return (
    <Card
      borderLeft="5px solid"
      borderLeftColor={paymentMethod.pmState === "ACTIVE" ? "green" : "gray.500"}
      bgColor={paymentMethod.pmState === "ACTIVE" ? "var(--card-bg)" : inactiveBgColor}
      filter={paymentMethod.pmState === "ACTIVE" ? "none" : inactiveFilter}
      color={
        paymentMethod.pmState === "ACTIVE" ? "var(--chakra-colors-chakra-body-text)" : inactiveColor
      }
      children={children}
    />
  );
};

export const PMCardHeader = ({ children }) => {
  return (
    <CardHeader>
      <Flex justifyContent="space-between" alignItems="start">
        {children}
      </Flex>
    </CardHeader>
  );
};

export const PMCardBody = ({ paymentMethod, children }) => {
  return (
    <CardBody>
      <VStack spacing={0.5} align="stretch" w="100%">
        {children}
        <PaymentMethodTags tags={paymentMethod.tags} />
      </VStack>
    </CardBody>
  );
};

export const PMCardFooter = ({ children }) => {
  return (
    <CardFooter fontSize="xs">
      <VStack spacing={0.5} align="stretch" w="100%">
        {children}
      </VStack>
    </CardFooter>
  );
};

export const CopyablePMID = ({ paymentMethod }) => {
  return (
    <CopyableText text={paymentMethod.paymentMethodId} buttonSize="xs">
      <DetailText>{paymentMethod.paymentMethodId}</DetailText>
    </CopyableText>
  );
};

///////////////////////////////////////////////////////////////////////////////////////////
//
// molecules
//

// guts of the card header without the card requirment.
// useful for modals and other places where a card is not needed
export const StandardPMHeader = ({ paymentMethod, actionItems }) => {
  return (
    <>
      <Box>
        <PaymentMethodLogo methodClass={paymentMethod.methodClass} />
        <Box fontSize="xs">
          <Box>{paymentMethod.displayString}</Box>
          <Box>
            <CopyablePMID paymentMethod={paymentMethod} />
          </Box>
        </Box>
      </Box>
      <Box>
        <PaymentMethodActionsMenu actionItems={actionItems} />
      </Box>
    </>
  );
};
export const StandardPMCardHeader = ({ paymentMethod, actionItems }) => {
  return (
    <PMCardHeader>
      <StandardPMHeader paymentMethod={paymentMethod} actionItems={actionItems} />
    </PMCardHeader>
  );
};

export const StandardPMCardFooter = ({ paymentMethod }) => {
  return (
    <PMCardFooter>
      <CardFooterRow label="Status" value={paymentMethod.pmState} />
      <CardFooterRow label="Created" value={formatDateTime(paymentMethod.created)} />
      <CardFooterRow label="Updated" value={formatDateTime(paymentMethod.updated)} />
    </PMCardFooter>
  );
};
