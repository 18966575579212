import { Tag } from "@chakra-ui/react";
import { isNilOrEmpty } from "utils";

const PayoutTierAccountTag = ({ tag }) => {
  const text = `${tag.namespace}: ${tag.tagName}`;
  return (
    <Tag variant="solid" size="sm">
      {text}
    </Tag>
  );
};

const getTagName = (tagName) => {
  switch (tagName) {
    case "entity":
      return <>Entity</>;
    case "entity_agent":
      return <>Entity Agent</>;
    case "payout_review_required":
      return <>Payout Review Required</>;
    default:
      return null;
  }
};

const DefaultAccountTag = ({ tag }) => {
  const text = getTagName(tag.tagName);
  if (text) {
    return (
      <Tag variant="solid" size="sm">
        {text}
      </Tag>
    );
  } else {
    return null;
  }
};

export const AccountTag = ({ tag }) => {
  if (isNilOrEmpty(tag)) {
    return null;
  }

  if (tag.namespace === "payout-tier") {
    return <PayoutTierAccountTag tag={tag} />;
  }
  return <DefaultAccountTag variant="solid" tag={tag} />;
};
