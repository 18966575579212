import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys,
);

const baseStyle = definePartsStyle({
  header: {
    borderBottom: "1px solid",
    borderColor: "gray.200",
    _dark: {
      borderColor: "whiteAlpha.200",
    },
  },
  footer: {
    borderTop: "1px solid",
    borderColor: "gray.200",
    _dark: {
      borderColor: "whiteAlpha.200",
    },
  },
  heading: {
    color: "gray.600",
    textTransform: "uppercase",
    _dark: {
      color: "gray.200",
    },
  },
});

const cardTheme = defineMultiStyleConfig({ baseStyle });
export default cardTheme;
