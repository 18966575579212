import * as R from "ramda";
import { Box, SimpleGrid } from "@chakra-ui/react";
import { Panel, PanelContent, PanelTitle } from "components/Panel";
import { getMillis } from "@tilia-tools/utils";
import { DataOrNo } from "./common";
import { useQueryStringVersion } from "context/useQueryStringVersion";
import PaymentCard from "./PaymentCard";
import { PaymentMethodCard } from "components/PaymentMethod";

const createdDesc = (a, b) => getMillis(b.created) - getMillis(a.created);
const sortByCreatedDesc = R.sort(createdDesc);

export const Payments = ({ data = [], onUpdate, onSelectPayments }) => {
  const isVersion = useQueryStringVersion();

  // sort the actives ahead of the inactives, sorted by created date within each group
  const groupedByState = R.groupBy(R.prop("pmState"))(data);
  const others = R.pipe(
    R.pickBy((value, key) => key !== "ACTIVE" && key !== "INACTIVE"),
    R.values,
    R.flatten,
  )(groupedByState);

  const sortedPaymentMethods = [
    ...sortByCreatedDesc(groupedByState.ACTIVE || []),
    ...sortByCreatedDesc(groupedByState.INACTIVE || []),
    ...sortByCreatedDesc(others || []),
  ];

  return (
    <Panel>
      <PanelTitle>Payment Methods ({data.length})</PanelTitle>
      <PanelContent>
        <DataOrNo data={data}>
          <Box maxW={"1600px"}>
            <SimpleGrid
              columns={{
                base: 1,
                lg: 2,
                xl: 3,
                "2xl": 4,
              }}
              spacing={8}
            >
              {isVersion(2)
                ? sortedPaymentMethods.map((paymentMethod) => (
                    <PaymentMethodCard
                      key={paymentMethod.paymentMethodId}
                      paymentMethod={paymentMethod}
                      onUpdate={onUpdate}
                      onSelectPayments={onSelectPayments}
                    />
                  ))
                : sortedPaymentMethods.map((method) => (
                    <PaymentCard
                      key={method.paymentMethodId}
                      method={method}
                      onUpdate={onUpdate}
                      onSelectPayments={onSelectPayments}
                    />
                  ))}
            </SimpleGrid>
          </Box>
        </DataOrNo>
      </PanelContent>
    </Panel>
  );
};
