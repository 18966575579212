import React from "react";
import ReactTooltip from "react-tooltip";
import { useTheme } from "styles";
import { isNilOrEmpty } from "@tilia-tools/utils";

export const Tooltip = ({ children, content, ...props }) => {
  const { currentTheme } = useTheme();
  return (
    <span
      {...props}
      data-border="true"
      data-border-color={currentTheme.colors.button.outline.border}
    >
      {children}
      <ReactTooltip>{content}</ReactTooltip>
    </span>
  );
};

// accepts a string or a React component as the content
export const ComplexTooltip = ({ id, children, content, ...props }) => {
  if (isNilOrEmpty(id)) return null;
  return (
    <>
      <span data-tip="tooltip" data-for={id}>
        {children}
      </span>
      <ReactTooltip id={id} data-effect="solid" place="top" {...props}>
        {content}
      </ReactTooltip>
    </>
  );
};
